function FeaturedInfoRules() { 
    return ( 
    <div className="featured responsive"> 
        <div className="featuredItem"> 
          <div className="featuredMoneyContainer"> 
            <span className="featuredMoney">
                <p>Always bet the opposite of Zac</p>
                <p>Never lay more than 6 points at home</p>
                <p>Never lay more than 3 points on the road</p>
                <p>Never lay more than 3 points in a divisional game</p>
            </span> 
          </div> 
        </div> 
    </div> 
    ); 
  } 
  export default FeaturedInfoRules;
  